const confetti = require('canvas-confetti');

document.addEventListener("click", function(e){
    const target = e.target.closest(".followBtn"); // Or any other selector.

    if(target){
        var rect = target.getBoundingClientRect()
        confetti({
            particleCount: 100,
            startVelocity: 30,
            ticks: 400,
            origin: { x: (rect.x / screen.width), y: (rect.y / screen.height) +0.03 },
            spread: 360,
        });
    }
});
