// Entry point for the build script in your package.json
import Rails from '@rails/ujs';


import { Turbo } from "@hotwired/turbo-rails"

//stimulus
import { Application } from "@hotwired/stimulus"
import Theme_preference_controller from "./controllers/theme_preference_controller"
const app = Application.start()
app.register("theme-preference", Theme_preference_controller)

Turbo.session.drive = false // ex turbolink, disabled by default: https://turbo.hotwired.dev/handbook/drive
import "chartkick/chart.js";
import "./canvas_confetti"
import "./dark_mode"

Rails.start();
