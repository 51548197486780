import {FetchRequest} from "@rails/request.js";

async function setThemePreference() {
    let preferredColorScheme;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { // check what the prefered color scheme is from the browser
        preferredColorScheme = 1; // if it is black then set it to 1 (enum = black)
    } else {
        preferredColorScheme = 0; // otherwise it is 0 (enum = white)
    }

    const url = window.location.origin + '/user/set_preferred_color_scheme';
    const data = {preferred_color_scheme: preferredColorScheme};

    try {
        const request = new FetchRequest('post', url, { body: JSON.stringify(data) })
        const response = await request.perform()
        if (response.ok) {
            const body = await response.text;
            console.log(body);
            return body;
        }
        else {
            return Promise.reject(Error(`HTTP error - status: ${response.status}`))
        }
    } catch (error) {
        console.error(error);
    }
}

setThemePreference().then();
